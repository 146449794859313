<template>
  <div class="layout-dashboard">
    <Toast position="top-right" />
    <div class="p-grid">
      <!--<Indicators :indicators="indicators" :canBeShown='isDonneurOrdre()' />-->
      <div v-if="isDonneurOrdre" class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-2">
          <span class="overview-title">Mes projets de marchés</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ stats?.countProjets || 0 }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-1">
          <span class="overview-title">Mes appels d'offres</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ stats?.countAppelsOffres || 0 }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isDonneurOrdre" class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-3">
          <span class="overview-title">Mes appels à manifestation d'intêrét</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ stats?.countAppelsManifestations || 0 }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <files-uploader
        :visible="dialogStates.uploading"
        :transferStatus="progressEvent"
        :item="selectedEntity"
        v-on:remove="onRemoveFile"
        v-on:upload="onUpload"
        v-on:close="onCloseUploadFile"
      >
      </files-uploader>

      <div class="p-col-12 p-xl-12">
        <div class="card no-gutter orders">
          <span>
            <Fieldset legend="Mes appels à manifestation d'intérêt" style="background-color: #cce5ff; font-size: 1.3em">
              <p style="font-size: 0.7em; font-weight: bold; text-align: justify; width: 900px">
                Bonjour, <br />
                La création des appels à manifestation d'intérêt se fait à partir de l'onglet «PPM». Pour créer un appel
                à manifestation d'intérêt, il faut cliquer sur le bouton « Réviser le plan de passation de marché» et le
                bouton de création apparaîtra au niveau des projets de marché. Ceci est visible que lorsque le plan de
                passation de marché a été validé par le secrétariat technique du CNSCL.
              </p>
            </Fieldset>
          </span>
          <br />
          <div class="p-d- p-jc-between">
            <div class="p-field">
              <label for="selectWipYear" class="p-mr-2">Sélectionner l'année de travail </label>
              <Dropdown
                @change="refreshStatsOnYear(selectedWipYear)"
                v-model="selectedWipYear"
                ref="selectWipYear"
                :options="plans"
                optionLabel="annee"
                placeholder="Année"
              />
              <InputText
                v-model="referenceKeyword"
                v-bind:style="{ marginLeft: '20px' }"
                placeholder="Rechercher par reference"
              />
            </div>
            <div>
              <DataTable
                ref="dt"
                :value="allAmi"
                data-key="id"
                :paginator="true"
                :rows="10"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="{totalRecords} avis de manifestation."
                responsiveLayout="scroll"
              >
                <Column field="id" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
                  <template #body="slotProps">
                    <span class="p-column-title">Intitulé</span>
                    <a
                      v-tooltip.top="slotProps.data.intitule"
                      style="cursor: pointer; font-weight: bold"
                      @click="onOpenDetails(slotProps.data.id)"
                      >{{ slotProps.data.intitule }}</a
                    >
                  </template>
                </Column>
                <Column
                  field="reference"
                  header="Référence"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">
                    <span class="p-column-title">Référence</span>
                    {{ slotProps.data.projetMarche.reference }}
                  </template>
                </Column>
                <Column
                  field="objet"
                  header="Objet"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">
                    <span class="p-column-title">Objet</span>
                    {{ slotProps.data.objet }}
                  </template>
                </Column>
                <Column
                  field="domaineMetiers"
                  header="Domaine(s) Métier(s)"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">
                    <span class="p-column-title"></span>
                    {{ slotProps.data.domaineMetiers.map((d) => d.libelle).join(', ') }}
                  </template>
                </Column>
                <Column
                  field="montantHt"
                  header="Montant d'attribution"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent: 1em"
                >
                  <template #body="slotProps">{{ toCurrency(slotProps.data.contrat?.montantHt) }}</template>
                </Column>
                <Column
                  field="datePublication"
                  header="Date de publication"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">{{ getFormattedDate(slotProps.data.datePublication) }}</template>
                </Column>
                <Column
                  field="dateLimite"
                  header="Date limite"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">{{ getFormattedDate(slotProps.data.dateLimite) }}</template>
                </Column>
                <Column
                  field="etat"
                  header="Statut"
                  :sortable="true"
                  :style="{ width: '120px' }"
                  bodyStyle="text-indent:1em"
                >
                  <template #body="slotProps">
                    <span v-if="slotProps.data.etat == 'VALIDE'" :class="'product-badge status-instock'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                    <span v-if="slotProps.data.etat == 'TRANSMIS'" :class="'product-badge status-lowstock'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                    <span v-if="slotProps.data.etat == 'CREE'" :class="'product-badge status-init'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                    <span v-if="slotProps.data.etat == 'ATTRIBUE'" :class="'product-badge status-instock'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                    <span v-if="slotProps.data.etat == 'RENVOYE'" :class="'product-badge status-outofstock'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                    <span v-if="slotProps.data.etat == 'FERME'" :class="'product-badge status-renewal'">{{
                      enumMap[slotProps.data.etat]
                    }}</span>
                  </template>
                </Column>
                <Column field="action" header="Actions" :sortable="false">
                  <template #body="slotProps" bodyStyle="text-indent:1em">
                    <span class="p-column-title">Actions</span>
                    <!-- TODO: revoir dans quel cas afficher les boutons. -->
                    <div class="dropdown">
                      <Button class="dropbtn" v-tooltip.top="'Afficher les actions'" icon="pi pi-chevron-down"></Button>
                      <div class="dropdown-content">
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canClose(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-danger"
                            @click="handleCloture(slotProps.data.id)"
                            icon="pi pi-check-circle"
                            v-tooltip.top="'Clôturer'"
                          />
                        </a>
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canAttribute(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-info"
                            @click="handleTenderSelection(slotProps.data.id)"
                            icon="pi pi-briefcase"
                            v-tooltip.top="'Sélectionner'"
                          />
                        </a>
                        <a v-show="slotProps.data.etat === 'RENVOYE'">
                          <Button
                            class="p-button-text p-button-danger"
                            icon="pi pi-book"
                            v-show="slotProps.data.etat === 'RENVOYE'"
                            v-tooltip.top="'Voir les corrections à apporter'"
                            @click="onOpenCorrections(slotProps.data.id)"
                          />
                        </a>
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canTransmit(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-warning"
                            icon="pi pi-send"
                            v-tooltip.top="'Transmettre au CNSCL'"
                            @click="handleConfirmSoumission(slotProps.data)"
                          />
                        </a>
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canModify(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-success"
                            icon="pi pi-pencil"
                            v-tooltip.top="'Modifier'"
                            @click="handleEdition(slotProps.data)"
                          />
                        </a>
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canModify(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-secondary"
                            icon="pi pi-file-pdf"
                            v-tooltip.top="'Importer des fichiers'"
                            @click="handleUpload(slotProps.data)"
                          />
                        </a>
                        <a
                          v-show="
                            this.plan?.statut === 'VALIDE' &&
                            canDelete(slotProps.data) &&
                            hasSubscribedForCurrentYearPlan(slotProps.data.projetMarche.planPassationMarcheAnnee)
                          "
                        >
                          <Button
                            class="p-button-text p-button-danger"
                            icon="pi pi-trash"
                            v-tooltip.top="'Supprimer'"
                            @click="handleConfirmRemoval(slotProps.data.id)"
                          />
                        </a>
                      </div>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Sidebar
      v-model:visible="dialogStates.detailsSelectedAmi"
      class="p-sidebar-md"
      position="right"
      @close="onUnsetSelectedAmiId"
    >
      <h4>Détails de l'appel à manifestation d'intérêt</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          Intitulé : <strong style="float: right">{{ selectedEntity?.intitule }}</strong>
        </li>
        <li class="detail-list-item">
          Objet : <strong style="float: right">{{ selectedEntity?.objet }}</strong>
        </li>
        <li class="detail-list-item">
          Type de régime :
          <b style="float: right">{{
            selectedEntity?.projetMarche?.regimes.map((regime) => enumMap[regime]).join(', ')
          }}</b>
        </li>
        <li class="detail-list-item">
          État : <strong style="float: right">{{ enumMap[selectedEntity?.etat] }}</strong>
        </li>
        <li class="detail-list-item">
          Motif de renvoi : <strong style="float: right">{{ selectedEntity?.motifRenvoi || 'N/A' }}</strong>
        </li>
        <li class="detail-list-item">
          Référence : <strong style="float: right">{{ selectedEntity?.projetMarche.reference }}</strong>
        </li>
        <li class="detail-list-item">
          Projet de marché associé : <strong style="float: right">{{ selectedEntity?.projetMarche?.intitule }}</strong>
        </li>
        <li class="detail-list-item">
          Détails :
          <b style="float: right"
            ><p style="font-size: 1rem">{{ selectedEntity?.details }}</p></b
          >
        </li>
        <li class="detail-list-item">
          Instructions :
          <b style="float: right"
            ><p style="font-size: 1rem">{{ selectedEntity?.instructions }}</p></b
          >
        </li>
        <li class="detail-list-item">
          Date de publication :
          <strong style="float: right">{{ getFormattedDate(selectedEntity?.datePublication) }}</strong>
        </li>
        <li class="detail-list-item">
          Date limite : <strong style="float: right">{{ getFormattedDate(selectedEntity?.dateLimite) }}</strong>
        </li>
      </ul>

      <br />
      <Panel header="Pièces jointes">
        <ul style="list-style: none; padding: 0">
          <li class="detail-list-item" v-for="(attachment, index) in selectedEntity?.piecesJointes" :key="index">
            <a style="cursor: pointer" @click="onDownload($event, attachment.urlPath)"
              ><img :src="getIconFromFileName(attachment.name)" width="25" />
              {{ getFileNameFromUrl(attachment.name) }}</a
            >
          </li>
        </ul>
      </Panel>
    </Sidebar>

    <Dialog :visible="dialogStates.updating" :modal="true" @update:visible="handleToggleModal" class="p-fluid">
      <template #header>
        <h4>Modification d'un appel à manifestation d'intérêt</h4>
      </template>
      <p>
        <strong
          ><u>NB :</u> Les champs dont le libellé se termine par <span class="p-text-bold text-red">*</span> sont
          obligatoires.</strong
        >
      </p>
      <div class="p-field p-col-12">
        <label for="intitule-id">Intitulé <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.ami.intitule.$model"
          :class="{ 'p-invalid': v$.ami.intitule.$invalid && formsState.avisManifestation.submitted }"
          id="intitule-id"
          type="text"
        />
        <small v-if="v$.ami.intitule.$invalid && formsState.avisManifestation.submitted" class="p-error">
          L'intitulé de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="objet">Objet <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.ami.objet.$model"
          :class="{ 'p-invalid': v$.ami.objet.$invalid && formsState.avisManifestation.submitted }"
          id="objet"
          type="text"
        />
        <small v-if="v$.ami.objet.$invalid && formsState.avisManifestation.submitted" class="p-error">
          L'objet de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="reference-id">Référence <span class="p-text-bold text-red">*</span></label>
        <InputText
          id="reference-id"
          type="text"
          disabled="true"
          v-model="v$.ami.reference.$model"
          :class="{ 'p-invalid': v$.ami.reference.$invalid && formsState.avisManifestation.submitted }"
        />
        <small v-if="v$.ami.reference.$invalid && formsState.avisManifestation.submitted" class="p-error">
          La référence de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="details-id">Détails de l'appel à manifestation d'intérêt</label>
        <Textarea
          id="details-id"
          v-model="v$.ami.details.$model"
          :class="{ 'p-invalid': v$.ami.details.$invalid && formsState.avisManifestation.submitted }"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.ami.details.$invalid && formsState.avisManifestation.submitted" class="p-error">
          Les détails de l'appel à manifestation d'intérêt sont obligatoires.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="instructions-id"
          >Instructions de l'appel à manifestation d'intérêt <span class="p-text-bold text-red">*</span></label
        >
        <Textarea
          :class="{ 'p-invalid': v$.ami.instructions.$invalid && formsState.avisManifestation.submitted }"
          v-model="v$.ami.instructions.$model"
          id="instructions-id"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.ami.instructions.$invalid && formsState.avisManifestation.submitted" class="p-error">
          Les instructions de l'appel à manifestation d'intérêt.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-lancement">Date de publication <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-lancement"
          :showIcon="true"
          dateFormat="dd/mm/yy"
          v-model="v$.ami.datePublication.$model"
          :class="{ 'p-invalid': v$.ami.datePublication.$invalid && formsState.avisManifestation.submitted }"
        />
        <small v-if="!v$.ami.datePublication.required && formsState.avisManifestation.submitted" class="p-error">
          La date de publication de l'appel à manifestation d'intérêt est obligatoire.
        </small>
        <small
          v-if="v$.ami.datePublication.maxValue.$invalid && formsState.avisManifestation.submitted"
          class="p-error"
        >
          La date de publication de l'appel à manifestation d'intérêt doit être inférieur à la date d'attribution.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-attribution">Date limite <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-attribution"
          v-model="v$.ami.dateLimite.$model"
          :class="{ 'p-invalid': v$.ami.dateLimite.$invalid && formsState.avisManifestation.submitted }"
          :showIcon="true"
          dateFormat="dd/mm/yy"
        />
        <small v-if="!v$.ami.dateLimite.required && formsState.avisManifestation.submitted" class="p-error">
          La date limite de l'appel à manifestation d'intérêt est obligatoire.
        </small>
        <small v-if="v$.ami.dateLimite.minValue.$invalid && formsState.avisManifestation.submitted" class="p-error">
          La date d'attribution de l'appel à manifestation d'intérêt doit être supérieur à la date d'attribution.
        </small>
      </div>

      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button
          label="Enregistrer les modifications"
          class="p-button p-component p-button-outlined p-button-success"
          :loading="isSubmittingAmi === 'SUBMIT_REQUESTED'"
          @click="onSubmit('UPDATE')"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.deletion"
      :style="{ width: '600px' }"
      header="Êtes-vous sûr de vouloir supprimer l'appel à manifestation d'intérêt ?"
      :modal="true"
      class="p-fluid"
    >
      <template #footer>
        <Button
          label="NON"
          icon="pi pi-delete"
          class="p-button p-component p-button-outlined p-button-danger"
          @click="hideDialog"
        />
        <Button
          label="OUI"
          icon="pi pi-delete"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleRemoval"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.soumission"
      :style="{ width: '600px' }"
      header="Voulez-vous soumettre l'appel à manifestation d'intérêt au CNSCL ?"
      :modal="true"
      class="p-fluid"
    >
      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button
          label="OUI"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleEtatToSoumis"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.rejection"
      :style="{ width: '600px' }"
      header="Votre appel à manifestation d'intérêt a été rejeté pour les motifs suivants :"
      :modal="true"
      class="p-fluid"
    >
      <h5 style="color: red">{{ selectedEntity.motifRejet }}</h5>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.revocation"
      :style="{ width: '600px' }"
      header="Votre appel à manifestation d'intérêt doit être corrigé pour les motif suivants :"
      :modal="true"
      class="p-fluid"
    >
      <h5 style="color: red">{{ selectedEntity.motifRenvoi }}</h5>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.cloture"
      :style="{ width: '600px' }"
      header="Etes-vous sûr de vouloir clôturer définitivement cet appel à manifestation d'intérêt ? Attention cet action est irréversible."
      :modal="true"
      class="p-fluid"
    >
      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button
          label="OUI"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleEtatToCloture"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.tender"
      :style="{ width: '1000px' }"
      header="Candidats ciblés pour l'appel à manifestation d'intérêt"
      @hide="onUnsetSelectedAmiId"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <div class="prestataires" v-if="selectedEntity.entreprisesChoisis.length > 0">
          <div class="prestataires__all">
            <h3>Liste des prestataires choisis</h3>
            <ul class="prestataires-list">
              <li class="prestataires-item item--titled">
                <input
                  type="checkbox"
                  :value="false"
                  v-model="togglePresValueIds"
                  @change="onTogglePrestatairesValuesIds"
                />
                Tout sélectionner
              </li>
              <li>
                <input
                  style="width: 100%; margin: 10px 0; padding: 5px 5px"
                  v-model="companyNameKeyword"
                  type="text"
                  placeholder="Recherche"
                />
              </li>
              <li>
                <select v-model="companyRankKeyword">
                  <option value="">Choisir un profil</option>
                  <option :value="tarif.rang" :key="tarif.id" v-for="tarif in tarifs">{{ tarif.adherant }}</option>
                </select>
              </li>
              <li
                class="prestataires-item"
                v-for="prestataire in choosenEnterprises"
                :key="prestataire.id"
                :class="{ 'prestataires--selected': prestataire.id === selectedPrestataire?.id }"
              >
                <input type="checkbox" :value="prestataire.id" v-model="ami.entreprisesAttribueesIds" />
                <span @click="seePrestataireDetails(prestataire.id)"
                  >{{ prestataire.name }} - Créé le {{ prestataire.companyCreatedAt.split('-').reverse().join('/') }} -
                  {{ enumMap[prestataire.rangSouscriptionEnCours] }}</span
                >
              </li>
            </ul>
            <h6 v-if="attribution.isEmpty" style="color: red">Veuillez sélectionner au moins un prestataire.</h6>
            <br />
            <b style="font-size: 20px">Montant de la prestation : </b>
            <b style="font-size: 17px; color: #696969">{{ toCurrency(Number(attribution.montantHt)) }}</b> <br /><br />
            <InputText
              type="number"
              v-model="attribution.montantHt"
              id="montantHt-id"
              :class="{ 'p-invalid': attribution.hasError }"
              :style="{ width: '98%' }"
              placeholder="Veuillez saisir le montant de l'appel à manifestation d'intérêt."
            />
            <h6 v-if="attribution.hasError" style="color: red">
              Le montant d'attribution de l'appel à manifestation d'intérêt est obligatoire.
            </h6>
          </div>
          <div class="prestataires__details" v-if="selectedPrestataire?.id">
            <h3 class="selected-prestataire__title">{{ selectedPrestataire?.name }}</h3>
            <ul class="selected-prestataire__list">
              <li class="selected-prestataire__item">
                Adresse : <strong class="to--right">{{ selectedPrestataire?.address }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Numéro d'identification :
                <strong class="to--right">{{ selectedPrestataire?.identificationNumber }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Registre de commerce : <strong class="to--right">{{ selectedPrestataire?.tradeRegister }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Date de création :
                <strong class="to--right">{{ getFormattedDate(selectedPrestataire?.companyCreatedAt) }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Email : <strong class="to--right">{{ selectedPrestataire?.email }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Téléphone 1 : <strong class="to--right">{{ selectedPrestataire?.mobile }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Téléphone 2 : <strong class="to--right">{{ selectedPrestataire?.pointFocal.phone }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Site web : <strong class="to--right">{{ selectedPrestataire?.www }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Profil : <strong class="to--right">{{ enumMap[selectedPrestataire?.rangSouscriptionEnCours] }}</strong>
              </li>
              <li class="selected-prestataire__item">
                Forme juridique : <strong class="to--right">{{ selectedPrestataire?.formeJuridique?.libelle }}</strong>
              </li>
              <!--<li class='selected-prestataire__item'>Domaines métiers: <strong class='to--right'>{{ selectedPrestataire?.domaineMetiers.map(o => o.libelle).join(", ") }}</strong></li>-->
              <li class="selected-prestataire__item">
                Pays : <strong class="to--right">{{ selectedPrestataire?.pays?.name }}</strong>
              </li>
            </ul>
          </div>
        </div>
        <div v-else style="text-align: center">
          <h3>Aucune entreprise choisie.</h3>
        </div>
      </div>
      <template #footer>
        <Button
          label="Je sélectionne"
          class="p-button p-component p-button-outlined p-button-success"
          @click="onSubmit('ATTRIBUTED')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';

import DateFormatMixin from '@/mixins/date-format';
import EnumFormatMixin from '@/mixins/enum-format';
import YearSelectorMixin from '@/mixins/year-selector-mixin';
import LoggedUserMixin from '@/mixins/logged-user';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';
import { maxValue, minValue } from '@vuelidate/validators';
import PpmActionsMixin from '@/mixins/ppm-actions-mixin';
import FilesUploader from '@/components/ami/files-uploader';
import http from '../../services/http.service';
import FilesMixin from '../../mixins/file-view';

export default {
  components: {
    // Indicators
    FilesUploader,
  },
  mixins: [DateFormatMixin, EnumFormatMixin, LoggedUserMixin, PpmActionsMixin, FilesMixin, YearSelectorMixin],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      companyNameKeyword: '',
      companyRankKeyword: '',
      referenceKeyword: '',
      isInAmiModule: true,
      attribution: {
        montantHt: null,
        hasError: false,
        isEmpty: false,
      },
      togglePresValueIds: false,
      isUploading: false,
      selectedPrestataire: null,
      indicators: [
        { title: 'Mes Appels à manifestation', total: 0, color: 'box-1', permission: true },
        { title: 'Mes plans de passations de marché', color: 'box-2', total: 0, permission: true },
        { title: "Mes appels à manifestation d'intêrét", color: 'box-3', total: 0, permission: true },
      ],
      progressEvent: null,
      domaine: null,
      dialogStates: {
        detailsSelectedAmi: false,
        updating: false,
        uploading: false,
        validation: false,
        rejection: false,
        revocation: false,
        deletion: false,
        soumission: false,
        tender: false,
        cloture: false,
      },
      formsState: {
        avisManifestation: {
          submitted: false,
        },
      },
      ami: {
        id: null,
        intitule: null,
        reference: null,
        objet: null,
        domaineMetiersIds: [],
        entreprisesChoisisIds: [],
        entreprisesAttribueesIds: [],
        projetMarcheId: null,
        details: null,
        instructions: null,
        datePublication: null,
        dateLimite: null,
        etat: 'CREE',
      },
    };
  },
  validations() {
    return {
      ami: {
        intitule: {
          required,
        },
        reference: {
          required,
        },
        objet: {
          required,
        },
        details: {
          required,
        },
        instructions: {
          required,
        },
        datePublication: {
          required,
          maxValue: maxValue(this.ami.dateLimite),
        },
        dateLimite: {
          required,
          minValue: minValue(this.ami.datePublication),
        },
      },
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.setEntities({ entities: { data: [] } });
    this.fetchAllPlans({ entrepriseId: user.entreprise.id });
    // this.fetchAll({ queryParams: { entreprises: user.entreprise.id, sort: 'datePublication,desc' } });
    this.fetchStats({ queryParams: { annee: new Date().getFullYear() } });
    this.fetchTarifs();
  },
  computed: {
    ...mapGetters({
      isSubmittingAmi: 'ami/getSubmitting',
      isEditingAmi: 'ami/getIsEditing',
      selectedEntity: 'ami/getEntity',
      isLoading: 'ami/getIsLoading',
      allAmi: 'ami/getAllEntitiesByReferencesOrNot',
      plans: 'ppm/getAllEntities',
      errors: 'ami/getErrors',
      plan: 'ppm/getEntity',
      stats: 'stats/getStats',
      entrepriseDomaineMetiers: 'auth/userDomaineMetiers',
      tarifs: 'tarifs/getAllEntities',
    }),
    choosenEnterprises() {
      const data =
        this.selectedEntity?.entreprisesChoisis?.filter((e) =>
          e.name.toLowerCase().includes(this.companyNameKeyword.toLocaleLowerCase())
        ) || [];
      console.log('getChoosenEnterprises', { data });
      if (this.companyRankKeyword) {
        return data.filter((enterprise) => enterprise.rangSouscriptionEnCours === this.companyRankKeyword);
      }
      return data;
    },
  },
  methods: {
    ...mapActions({
      fetchStats: 'stats/fetchStats',
      fetchAllPlans: 'ppm/fetchAllByEntrepriseId',
      fetchAll: 'ami/fetchAllByPlanId',
      fetchPrestatairesById: 'ami/fetchPrestatairesById',
      fetchProjetsMarches: 'projetsMarches/fetchAllForAmiByEntrepriseIdAnnee',
      createOrUpdate: 'ami/createOrUpdate',
      setState: 'ami/setState',
      remove: 'ami/delete',
      attributeToEnterprise: 'ami/attributeToEnterprises',
    }),
    ...mapMutations({
      setEntities: 'ami/setEntities',
      setSelectedPpmId: 'ppm/setSelectedEntityId',
      searchByReference: 'ami/setReferenceKeyword',
      fetchTarifs: 'tarifs/fetchAll',
    }),

    toCurrency(value) {
      if (value) return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
      return;
    },

    checkFieldValidation(fieldName) {
      return this.errors.validation[fieldName];
    },

    onTogglePrestatairesValuesIds() {
      this.togglePresValueIds = !!this.togglePresValueIds;
      if (this.togglePresValueIds === true)
        this.ami.entreprisesAttribueesIds = this.selectedEntity.entreprisesChoisis.map((prestataire) => prestataire.id);
      else this.ami.entreprisesAttribueesIds = [];
    },

    onSubmit(flag) {
      if (flag === 'UPDATE') {
        this.formsState.avisManifestation.submitted = true;
        if (this.v$.ami.$invalid) {
          return;
        }
      }

      this.attribution.isEmpty = false;
      this.attribution.hasError = false;

      if (flag === 'ATTRIBUTED' && this.ami.entreprisesAttribueesIds.length == 0) {
        this.attribution.isEmpty = true;
        return;
      }

      /*if ((flag === 'ATTRIBUTED' && this.attribution.montantHt === null) || this.attribution.montantHt === '') {
        this.attribution.hasError = true;
        return;
      }*/

      this.attribution.hasError = false;

      let data = {
        ...this.ami,
        objet_ami: this.ami.objet,
        datePublication: this.getFormattedDate(this.ami.datePublication, 'YYYY-MM-DD', 'DD/MM/YYYY'),
        dateLimite: this.getFormattedDate(this.ami.dateLimite, 'YYYY-MM-DD', 'DD/MM/YYYY'),
      };
      if (flag === 'UPDATE') {
        // data = { ...data, etat: 'TRANSMIS' };
        data = { ...data };
      }
      if (flag === 'ATTRIBUTED') {
        data = { ...data, etat: 'ATTRIBUE', montantHt: parseFloat(this.attribution.montantHt) };
        this.attributeToEnterprise({ data });
      } else {
        this.createOrUpdate({ data });
      }
    },

    handleEtatToSoumis() {
      this.setState({
        id: this.ami.id,
        state: 'TRANSMIS',
      });
      this.dialogStates.soumission = false;
    },

    handleEtatToCloture() {
      this.setState({
        id: this.selectedEntity.id,
        state: 'FERME',
      });
      this.dialogStates.cloture = false;
    },

    onUnsetSelectedAmiId() {
      this.$store.commit('ami/setSelectedEntityId', null);
    },

    handleTenderSelection(id) {
      this.$store.commit('ami/setSelectedEntityId', id);
      this.dialogStates = {
        deletion: false,
        revocation: false,
        validation: false,
        soumission: false,
        rejection: false,
        tender: true,
        cloture: false,
      };
    },

    handleCloture(id) {
      this.$store.commit('ami/setSelectedEntityId', id);
      this.hideDialog();
      this.dialogStates.cloture = true;
    },

    seePrestataireDetails(id) {
      this.selectedPrestataire = this.selectedEntity.entreprisesChoisis.find((prestataire) => prestataire.id === id);
    },

    handleConfirmSoumission(data) {
      this.$store.commit('ami/setSelectedEntityId', data.id);
      this.ami = this.getFormValues(data);
      this.dialogStates = {
        deletion: false,
        revocation: false,
        validation: false,
        soumission: true,
        rejection: false,
      };
    },

    handleRejection(ami) {
      this.$store.commit('ami/setSelectedEntityId', ami.id);
      this.dialogStates = {
        deletion: false,
        revocation: false,
        validation: false,
        soumission: false,
        rejection: true,
      };
    },

    handleRevocation(ami) {
      this.$store.commit('ami/setSelectedEntityId', ami.id);
      this.dialogStates = {
        deletion: false,
        revocation: true,
        validation: false,
        soumission: false,
        rejection: false,
      };
    },

    handleConfirmRemoval(id) {
      this.$store.commit('ami/setSelectedEntityId', id);
      this.dialogStates = {
        revocation: false,
        deletion: true,
        validation: false,
        soumission: false,
        rejection: false,
      };
    },

    handleRemoval() {
      this.remove({ amiId: this.ami.id });
      this.hideDialog();
    },

    handleToggleModal() {
      // this.$store.commit("ami/setIsEditing", !this.isEditingAmi);
      this.dialogStates.updating = !this.dialogStates.updating;
    },

    handleEdition(object) {
      this.$store.commit('ami/setSelectedEntityId', object.id);
      // this.$store.commit("ami/setIsEditing", true);
      this.ami = this.getFormValues(object);
      this.dialogStates.updating = true;
    },

    handleUpload(object) {
      this.$store.commit('ami/setSelectedEntityId', object.id);
      this.ami = this.getFormValues(object);
      this.dialogStates.uploading = true;
    },

    onUpload(events) {
      this.isUploading = true;
      http
        .post('/appel-manifestations-interets/' + this.ami.id + '/uploadPiecesJointes', events, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          onUploadProgress: (progressEvent) => {
            this.progressEvent = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        })
        .then((response) => {
          this.$store.commit('ami/setEntity', { entity: response.data });
          this.isUploading = true;
          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail: 'Les fichiers ont été importés avec succès.',
            life: 7000,
          });
          this.onCloseUploadFile();
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Echec',
            detail:
              "Une erreur est survenue lors de l'import des fichiers, veuillez recommencer ou contacter votre administrateur.",
            life: 7000,
          });
        });
    },

    onRemoveFile(attachmentId) {
      http
        .post(
          '/appel-manifestations-interets/' + this.ami.id + '/deletePiecesJointes',
          { attachmentId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((response) => {
          this.$store.commit('ami/setEntity', { entity: response.data });
          this.isUploading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail: 'Le fichier a bien été supprimé.',
            life: 7000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Echec',
            detail:
              'Une erreur est survenue lors de la suppression du fichiers, veuillez recommencer ou contacter votre administrateur.',
            life: 7000,
          });
        });
    },

    onCloseUploadFile() {
      this.$store.commit('ami/setSelectedEntityId', null);
      this.resetAppelManifestationInteretForm();
      this.dialogStates.uploading = false;
    },

    onOpenDetails(amiId) {
      this.$store.commit('ami/setSelectedEntityId', amiId);
      this.dialogStates.detailsSelectedAmi = true;
    },

    onOpenRejection(amiId) {
      this.$store.commit('ami/setSelectedEntityId', amiId);
      this.dialogStates.rejection = true;
    },

    onOpenCorrections(amiId) {
      this.$store.commit('ami/setSelectedEntityId', amiId);
      this.dialogStates.revocation = true;
    },

    getFormValues(value) {
      return {
        id: value?.id,
        intitule: value?.intitule,
        reference: value?.projetMarche.reference,
        objet: value?.objet,
        domaineMetiersIds: value?.domaineMetiers.map((domaineMetier) => domaineMetier.id),
        projetMarcheId: value?.projetMarche.id,
        details: value?.details,
        instructions: value?.instructions,
        datePublication: new Date(value?.datePublication),
        dateLimite: new Date(value?.dateLimite),
        etat: value?.etat,
        entreprisesChoisisIds: value?.entreprisesChoisis.map((entreprise) => entreprise.id),
        entreprisesAttribueesIds: value?.entreprisesAttribuees.map((entreprise) => entreprise.id),
      };
    },

    resetAppelManifestationInteretForm() {
      this.ami = {
        id: null,
        intitule: null,
        reference: null,
        objet: null,
        domaineMetiersIds: [],
        entreprisesChoisisIds: [],
        entreprisesAttribueesIds: [],
        projetMarcheId: null,
        details: null,
        instructions: null,
        datePublication: null,
        dateLimite: null,
        etat: 'CREE',
      };
      this.formsState.avisManifestation.submitted = false;
    },

    hideDialog() {
      this.resetAppelManifestationInteretForm();

      this.dialogStates.updating = false;
      this.dialogStates.revocation = false;
      this.dialogStates.tender = false;
      this.dialogStates.soumission = false;
      this.dialogStates.deletion = false;
      this.dialogStates.rejection = false;
      this.dialogStates.cloture = false;
    },
  },
  watch: {
    referenceKeyword: function (value) {
      this.searchByReference({ keyword: value });
    },

    selectedEntity: function (value) {
      if (value && value.entreprisesChoisis.length > 0) this.selectedPrestataire = value.entreprisesChoisis[0];
      if (value) {
        this.ami = this.getFormValues(value);
        this.attribution.montantHt = value.contrat ? value.contrat.montantHt : null;
        this.attribution.hasError = false;
      }
    },

    plan: function (value) {
      if (value.id) this.selectedWipYear = value;
    },

    selectedWipYear: function (value) {
      this.refreshStatsOnYear(value);
    },

    isSubmittingAmi: function (newValue, oldValue) {
      if (newValue === SUBMIT_SUCCEED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'success',
          summary: 'Accomplie',
          detail: "Les modifications sur l'appel à manifestation d'intérêt ont bien été effectuées.",
          life: 7000,
        });
        this.hideDialog();
      }

      if (newValue === SUBMIT_FAILED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Une erreur est survenue lors de la modification sur l'appel à manifestation d'intérêt.",
          life: 7000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.width--500px {
  width: 500px !important;
}
.prestataires-list,
.selected-prestataire__list {
  list-style: none;
  padding: 0;
}
.prestataires-item {
  border-bottom: 1px solid #e7e7e7;
  padding: 5px;
  cursor: pointer;
}
.prestataires-item:hover {
  background-color: #f3f3f3;
}
.text-danger {
  color: #f44336 !important;
}
.prestataires {
  display: flex;
}
.prestataires--selected {
  background-color: #ddf0b2;
}
.prestataires__all {
  width: 60%;
}
.prestataires__details {
  width: 40%;
  border-left: 2px solid #e7e7e7;
  padding-left: 20px;
}
.selected-prestataire__item {
  list-style: none;
  padding: 0;
}

.to--right {
  float: right;
}

.text-red {
  color: #db3c30;
}

.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-init {
    background: #deddf5;
    color: #101b61;
  }
  &.status-renewal {
    background: #eccfff;
    color: #694382;
  }
}

/* Dropdown Button */
.dropbtn {
  background-color: #ccd2d8;
  color: rgb(43, 46, 46);
  text-align: center;
  font-size: 10px;
  border: none;
  margin-left: 0.8em;
  margin-bottom: 0.3em;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: inherit;
  display: inline-block;
  flex-direction: column;
  padding: 12px 16px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 16px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 8px 8px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:focus-within > .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #cce5ff;
  color: rgb(43, 46, 46);
}
</style>
